import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import silvestre from '@/assets/silvestre.svg';
import colors from '@/assets/colors';
import Container from '@/components/main-content';
import Experience from '@/components/resume/experience';
import Education from '@/components/resume/education';
import Award from '@/components/resume/award';
import Recommendation from '@/components/resume/recommendation';
import Triangle from '@/components/triangle';
import '@/stylesheets/resume';
import { media } from '@/utils';

const SectionHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid #EEE;
  display: flex;
  margin: 2em 0 5em;

  ${media.phone`
    flex-direction: column-reverse;
    margin: 0 0 2em;
  `}

  img {
    height: auto;
    margin-bottom: -1px;
    margin-right: 2em;
    width: 25%;

    ${media.phone`
      width: 66%;
    `}
  }

  h1 {
    ${media.phone`
      margin-top: 0;
    `}
  }
`;

const HeaderContent = styled.div`
  h1, h3 {
    font-weight: 300;
  }

  h1 {
    color: ${colors.brand.secondary};

    ${media.phone`
      line-height: 1.25;
      margin: 0;
    `}
  }

  h3 {
    ${media.phone`
      line-height: 1.25;
      margin: .5em 0 2em;
    `}
  }

  p {
    margin-bottom: 0;
  }
`;

const Toolbox = styled.ul`
  list-style: none;
  margin-bottom: 2em;
  padding: 0;
  position: relative;
  text-align: center;

  li {
    display: inline-block;
    margin: 1em;
  }

  img {
    display: block;
    height: 4em;
    width: auto;

    ${media.phone`
      height: 2.5em;
    `}
  }
`;

const getData = (data, key) => {
  return data.site.siteMetadata[key];
};

const Resume = ({ data }) => {
  const awards = getData(data, 'awards');
  const experience = getData(data, 'experience');
  const education = getData(data, 'education');
  const recommendations = getData(data, 'recommendations');
  const toolbox = getData(data, 'toolbox');

  return (
    <Container className='main-content'>
      <Helmet bodyAttributes={{ class: 'resume' }} />
      <SectionHeader>
        <img alt="mugshot" src={silvestre} />
        <HeaderContent>
          <h1>Silvestre Herrera</h1>
          <h3>Developer, designer, problem solver, and, above all, fast learner.</h3>
        </HeaderContent>
      </SectionHeader>
      <Triangle color={colors.brand.secondary} size={1000} />
      <Triangle yOffset={'20vh'} color={colors.brand.primary} reverse size={1000} />
      <h1>Toolbox</h1>
      <Toolbox>
        {toolbox.map(tech => (
          <li data-tooltip={tech.label} key={tech.name}>
            <img alt={tech.label} src={require(`@/assets/technologies/${tech.name}.svg`)} />
          </li>
        ))}
      </Toolbox>
      <h1>Experience</h1>
      {experience.map((e, i) => <Experience key={i} data={e} />)}
      <h1>Education</h1>
      {education.map((e, i) => <Education key={i} data={e} />)}
      <h1>Recommendations</h1>
      {recommendations.map((r, i) => <Recommendation key={i} data={r} />)}
      <h1>Awards</h1>
      {awards.map((a, i) => <Award key={i} data={a} />)}
    </Container>
  );
};

Resume.propTypes = {
  data: PropTypes.object.isRequired
};

export const query = graphql`
  query ResumePageQuery {
    site {
      siteMetadata {
        experience {
          employer
          location
          title
          description
          date {
            from
            to
          }
        }
        education {
          degree
          school
          description
          date {
            from
            to
          }
        }
        awards {
          award {
            title
            badge
          }
          description
          date
          company {
            name
            logo
          }
        }
        toolbox {
          name
          label
        }
        recommendations {
          quote
          author
          company
        }
      }
    }
  }
`;

export default Resume;
