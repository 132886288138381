import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Colors from '@/assets/colors';
import { getAsHTML } from '@/utils';

const Container = styled.div`
  margin-bottom: 2em;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.h3`
  color: ${Colors.brand.secondary};
  margin-bottom: .125em;
  text-transform: uppercase;
`;

const Employer = styled.em`
  color: ${Colors.text.secondary};
  font-size: .9em;
  font-weight: 300;
  text-transform: none;
`;

const Date = styled.small`
  display: block;
  color: ${Colors.text.secondary};
  margin-top: .5em;
`;

const Experience = ({ data }) => {
  const date = data.date.to
    ? `${data.date.from} — ${data.date.to}`
    : `Since ${data.date.from}`;

  return (
    <Container>
      <Title>
        {data.title} <Employer>@ {data.employer}</Employer>
      </Title>
      <Date>{date}</Date>
      <div dangerouslySetInnerHTML={getAsHTML(data.description)} />
    </Container>
  );
};

Experience.propTypes = {
  data: PropTypes.object.isRequired
};

export default Experience;
