import React from 'react';
import styled from 'styled-components';

import colors from '@/assets/colors';
import { getAsHTML } from '@/utils';

const Container = styled.div`
  & + & {
    margin-top: 3em;
  }
`;

const Quote = styled.blockquote`
  border-left: 2px solid rgba(0, 0, 0, .125);
  color: ${colors.text.primary}AA;
  font-style: italic;
  margin: 0;
  padding-left: 2em;
`;

const Cite = styled.cite`
  color: ${colors.text.primary};
`;

const Recommendation = ({ data }) => {
  return (
    <Container>
      <Quote dangerouslySetInnerHTML={getAsHTML(data.quote)} />
      <Cite>— {data.author}, {data.company}</Cite>
    </Container>
  );
};

export default Recommendation;
