import React from 'react';
import styled from 'styled-components';

import { media, getAsHTML } from '@/utils';

const images = {
  'tnw-badge': require('@/assets/tnw-startup-awards.png'),
  'tnw-logo': require('@/assets/tnw.svg')
};

const Container = styled.div`
  display: flex;
  flex-direction: row;

  ${media.phone`
    flex-direction: column;
  `};
`;

const Badge = styled.img`
  margin-right: 2rem;
  height: 15%;
  width: 15%;

  ${media.phone`
    height: auto;
    margin: 0 auto 2rem;
    height: 50%;
    width: 50%;
  `};

  ${media.tablet`
    height: 25%;
    width: 25%;
  `};
`;

const Details = styled.div``;

const CompanyLogo = styled.img`
  display: block;
  height: 2.5rem;

  ${media.phone`
    margin: auto;
  `}
`;

const Awards = ({ data }) => {
  return (
    <Container>
      <Badge alt="Award Badge" src={images[data.award.badge]} />
      <Details>
        <CompanyLogo alt="Company Logo" src={images[data.company.logo]} />
        <div dangerouslySetInnerHTML={getAsHTML(data.description)} />
      </Details>
    </Container>
  );
};

export default Awards;
