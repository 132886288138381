import styled from 'styled-components';

export default styled.div`
  animation: spin 60s linear infinite;
  animation-direction: ${props => props.reverse ? 'reverse' : ''};
  border-bottom: ${props => props.size * 0.001}px solid ${props => props.color};
  height: calc(${props => props.size}px * 0.86);
  right: calc(-20vw + ${props => props.xOffset || '0px'});
  min-width: ${props => props.size}px;
  mix-blend-mode: multiply;
  opacity: .5;
  pointer-events: none;
  position: fixed;
  top: calc(-20vh + ${props => props.yOffset || '0px'});
  transform-origin: 50% 66%;
  width: ${props => props.size}px;
  will-change: transform;
  z-index: 10;

  &::before, &::after {
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &::before {
    border-left: ${props => props.size * 0.001}px solid ${props => props.color};
    transform: skewX(-30.2deg) translate3d(0, 0, 0) translateX(calc(24.5% + ${props => props.size * 0.001}px * 5));
  }

  &::after {
    border-right: ${props => props.size * 0.001}px solid ${props => props.color};
    transform: skewX(30deg) translate3d(0, 0, 0) translateX(calc(-25% - ${props => props.size * 0.001}px / 2));
  }

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(359deg); }
  }
`;